<template>
  <div class="prospects">
    <create-prospect
      :create-modal="createModal"
      :selected-client="selectedClient"
      @closeCreateModal="closeCreateModal"
      @failedCreatingProspect="failedCreatingProspect"
      @prospectSaved="prospectSaved"
    ></create-prospect>
    <edit-prospect
      :edit-modal="editModal"
      :selected-client="selectedClient"
      :prospect="
        selectedProspects && selectedProspects.length
          ? selectedProspects[0]
          : {}
      "
      @closeEditModal="closeEditModal"
      @failedEditingProspect="failedEditingProspect"
      @prospectEditSaved="prospectEditSaved"
    ></edit-prospect>
    <import-prospects
      :import-prospects-modal="importProspectsModal"
      :selected-client="selectedClient"
      @closeImportProspectsModal="closeImportProspectsModal"
      @failedImportingProspects="failedImportingProspects"
      @prospectsImported="prospectsImported"
    ></import-prospects>
    <add-to-campaign
      :add-to-campaign-modal="addToCampaignModal"
      :selected-client="selectedClient"
      :selected-prospects="selectedProspects"
      @closeAddToCampaignModal="closeAddToCampaignModal"
      @failedAddingToCampaign="failedAddingToCampaign"
      @addedToCampaign="addedToCampaign"
    ></add-to-campaign>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn
        class="mx-2"
        fab
        dark
        small
        color="red darken-2"
        @click="deleteProspects()"
        v-if="selectedProspects && selectedProspects.length"
      >
        <v-icon dark> mdi-delete </v-icon>
      </v-btn>
      <v-btn
        class="mx-2"
        fab
        dark
        small
        color="primary"
        @click="editModal = true"
        v-if="selectedProspects && selectedProspects.length"
      >
        <v-icon dark> mdi-pencil </v-icon>
      </v-btn>
      <v-btn
        class="mx-2"
        fab
        dark
        small
        color="indigo darken-4"
        @click="addToCampaignModal = true"
      >
        <v-icon dark> mdi-package-variant-closed-plus </v-icon>
      </v-btn>
      <v-btn
        class="mx-2"
        fab
        dark
        small
        color="indigo"
        @click="createModal = true"
      >
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
      <v-btn
        class="mx-2"
        fab
        dark
        small
        color="grey darken-2"
        @click="importProspectsModal = true"
      >
        <v-icon dark> mdi-cloud-upload-outline </v-icon>
      </v-btn>
    </v-row>
    <v-row align="center">
      <v-col>
        <v-card flat>
          <v-tabs v-model="selectedTab" centered fixed-tabs>
            <v-tab :href="`#tab-new`"> Unassigned Prospects </v-tab>
            <v-tab :href="`#tab-active`"> Active Prospects </v-tab>
            <v-tab :href="`#tab-finished`"> Finished Prospects </v-tab>
          </v-tabs>
          <v-tabs-items v-model="selectedTab">
            <v-tab-item value="tab-new">
              <v-data-table
                v-model="selectedProspects"
                class="click-table my-8"
                :headers="headers"
                :items="newProspects"
                item-key="_id"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :loading="dataLoading"
                @click:row="handleRowClick"
                show-select
              >
                <template v-slot:item.createdAt="{ value }">
                  <time :title="value" :datetime="value">{{
                    formatDate(value)
                  }}</time>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item value="tab-active">
              <v-data-table
                v-model="selectedProspects"
                class="click-table my-8"
                :headers="headers"
                :items="assignedProspects"
                item-key="_id"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :loading="dataLoading"
                @click:row="handleRowClick"
                show-select
              >
                <template v-slot:item.createdAt="{ value }">
                  <time :title="value" :datetime="value">{{
                    formatDate(value)
                  }}</time>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item value="tab-finished">
              <v-data-table
                v-model="selectedProspects"
                class="click-table my-8"
                :headers="headers"
                :items="finishedProspects"
                item-key="_id"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :loading="dataLoading"
                @click:row="handleRowClick"
                show-select
              >
                <template v-slot:item.createdAt="{ value }">
                  <time :title="value" :datetime="value">{{
                    formatDate(value)
                  }}</time>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import CreateProspect from "@/components/CreateProspect.vue";
import EditProspect from "@/components/EditProspect.vue";
import ImportProspects from "@/components/ImportProspects.vue";
import AddToCampaign from "@/components/AddToCampaign.vue";
import { store } from "@/state/store.js";

export default {
  components: {
    CreateProspect,
    EditProspect,
    ImportProspects,
    AddToCampaign,
  },
  data() {
    return {
      prospects: [],
      selectedProspects: [],
      sortBy: "createdAt",
      selectedTab: "tab-new",
      sortDesc: true,
      dataLoading: true,
      createModal: false,
      editModal: false,
      addToCampaignModal: false,
      importProspectsModal: false,
      store,
    };
  },
  created() {
    this.loadProspects();
  },
  computed: {
    headers() {
      return [
        {
          text: "Company Name",
          value: "companyName",
        },
        {
          text: "First Name",
          value: "firstName",
        },
        {
          text: "Last Name",
          value: "lastName",
        },
        {
          text: "Email Address",
          value: "email",
        },
        {
          text: "Status",
          value: "prospectStatus",
        },
        { text: "Date Created", value: "createdAt" },
      ];
    },
    selectedClient() {
      return this.store.selectedClient;
    },
    newProspects() {
      return this.prospects.filter(
        (prospect) => prospect.prospectStatus === "NEW"
      );
    },
    assignedProspects() {
      return this.prospects.filter(
        (prospect) => prospect.prospectStatus === "ACTIVE"
      );
    },
    finishedProspects() {
      return this.prospects.filter(
        (prospect) =>
          prospect.prospectStatus !== "NEW" &&
          prospect.prospectStatus !== "ACTIVE"
      );
    },
  },
  watch: {
    selectedClient() {
      this.loadProspects();
    },
  },
  methods: {
    formatDate(dt) {
      return moment(dt).format("MM/DD/YYYY hh:mm");
    },
    async loadProspects() {
      try {
        const prospectsResp = await this.$http.get(
          `/prospects?_filter=${this.selectedClient}`
        );

        this.prospects = prospectsResp.data;
      } catch (err) {
        console.error(err);
      } finally {
        this.dataLoading = false;
      }
    },
    closeCreateModal() {
      this.createModal = false;
    },
    failedCreatingProspect(error) {
      console.log(error);
      this.createModal = false;
    },
    prospectSaved() {
      this.createModal = false;
      this.loadProspects();
    },
    closeAddToCampaignModal() {
      this.addToCampaignModal = false;
    },
    failedAddingToCampaign(error) {
      console.log(error);
      this.addToCampaignModal = false;
    },
    addedToCampaign() {
      this.addToCampaignModal = false;
      this.loadProspects();
    },
    closeEditModal() {
      this.editModal = false;
    },
    failedEditingProspect(error) {
      console.log(error);
      this.editModal = false;
    },
    prospectEditSaved() {
      this.loadProspects();
      this.editModal = false;
    },
    closeImportProspectsModal() {
      this.importProspectsModal = false;
    },
    failedImportingProspects(error) {
      console.log(error);
      this.importProspectsModal = false;
    },
    prospectsImported() {
      this.loadProspects();
      this.importProspectsModal = false;
    },
    handleRowClick(row) {
      this.$router.push({ name: "prospect", params: { prospectId: row._id } });
    },
    async deleteProspects() {
      await this.$http.post("/prospects/delete", {
        prospects: this.selectedProspects,
      });
      this.loadProspects();
    },
  },
};
</script>
