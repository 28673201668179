<template>
  <v-dialog
    v-model="addToCampaignModal"
    max-width="650px"
    @click:outside="closeAddToCampaignModal()"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Add Prospects To Campaign</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" class="mx-2" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-switch
                  v-model="useSelection"
                  :label="selectionLabel"
                ></v-switch>
              </v-col>
              <v-col cols="12" v-if="!useSelection">
                <v-autocomplete
                  v-model="selectedTag"
                  :items="tags"
                  :item-value="(tag) => tag._id"
                  :item-text="(tag) => tag.name"
                  label="Select prospects by tag*"
                  chips
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedCampaign"
                  :items="campaigns"
                  :item-value="(campaign) => campaign._id"
                  :item-text="(campaign) => campaign.name"
                  label="Add prospects to campaign*"
                  chips
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-slider
                  v-model="prospectCampaignSize"
                  thumb-label="always"
                  min="0"
                  step="50"
                  max="5000"
                  ticks
                  label="Add up to # prospects*"
                ></v-slider>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <small>* indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeAddToCampaignModal()"> Close </v-btn>
        <v-btn color="primary" text @click="addToCampaign()"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      selectedTag: undefined,
      selectedCampaign: undefined,
      campaigns: [],
      tags: [],
      useSelection: true,
      prospectCampaignSize: 0,
    };
  },
  async created() {
    const campaignResponse = await this.$http.get(
      `/campaigns/?_filter=${this.selectedClient}`
    );
    this.campaigns = campaignResponse.data;
    this.selectedCampaign = this.campaigns[0]._id;
    const tagsResponse = await this.$http.get(
      `/clients/tags/${this.selectedClient}`
    );
    this.tags = tagsResponse.data;
  },
  props: {
    addToCampaignModal: Boolean,
    selectedClient: String,
    selectedProspects: Array,
  },
  computed: {
    selectionLabel() {
      return `Add ${
        this.selectedProspects ? this.selectedProspects.length : 0
      } selected prospects to campaign`;
    },
  },
  methods: {
    closeAddToCampaignModal() {
      this.$emit("closeAddToCampaignModal");
    },
    async addToCampaign() {
      if (!this.$refs.form.validate()) {
        return;
      }

      try {
        const response = await this.$http.post(
          `/campaigns/${this.selectedCampaign}/prospects`,
          {
            selectedClient: this.selectedClient,
            selectedTag: this.selectedTag,
            selectedProspects: this.selectedProspects.map((p) => p._id),
            prospectCampaignSize: this.prospectCampaignSize,
          }
        );

        this.$emit("addedToCampaign", response.data);
      } catch (err) {
        this.$emit("failedAddingToCampaign", err);
      }
    },
  },
};
</script>
