<template>
  <v-dialog
    v-model="importProspectsModal"
    max-width="650px"
    @click:outside="closeImportProspectsModal()"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Import Prospects</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" class="mx-2" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-file-input
                  accept=".csv"
                  label="Select csv file*"
                  filled
                  v-model="csv.file"
                ></v-file-input>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedTags"
                  :items="tags"
                  :item-value="(tag) => tag._id"
                  :item-text="(tag) => tag.name"
                  label="Tags*"
                  chips
                  multiple
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <small>* indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeImportProspectsModal()"> Close </v-btn>
        <v-btn
          color="primary"
          text
          @click="importProspects()"
          :disabled="uploading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      csv: {},
      selectedTags: [],
      tags: [],
      uploading: false,
    };
  },
  async created() {
    const tagsResponse = await this.$http.get(
      `/clients/tags/${this.selectedClient}`
    );
    this.tags = tagsResponse.data;
  },
  props: {
    importProspectsModal: Boolean,
    selectedClient: String,
  },
  methods: {
    closeImportProspectsModal() {
      this.$emit("closeImportProspectsModal");
    },
    async importProspects() {
      if (!this.$refs.form.validate()) {
        return;
      }

      try {
        this.uploading = true;
        const fd = new FormData();
        console.log(this.csv.file);
        fd.append("file", this.csv.file);
        fd.append("tags", this.selectedTags);
        fd.append("clientId", this.selectedClient);
        const response = await this.$http.post("/prospects/import", fd);
        this.uploading = false;
        this.$emit("prospectsImported", response.data);
      } catch (err) {
        this.$emit("failedImportingProspects", err);
      }
    },
  },
};
</script>
