<template>
  <v-dialog
    v-model="createModal"
    max-width="650px"
    @click:outside="closeModal()"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Add Prospect</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" class="mx-2" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="companyName"
                  label="Company Name*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="firstName"
                  label="First Name*"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="lastName"
                  label="Last Name*"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="email"
                  label="Email Address*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="phone" label="Phone"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="title" label="Title"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="linkedInProfileUrl"
                  label="LinkedIn Profile Url"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="website"
                  label="Company Website"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <small>* indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeModal()"> Close </v-btn>
        <v-btn color="primary" text @click="createProspect()"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      companyName: "",
      title: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      linkedInProfileUrl: "",
      website: "",
    };
  },
  props: {
    createModal: Boolean,
    selectedClient: undefined,
  },
  methods: {
    closeModal() {
      this.$emit("closeCreateModal");
    },
    async createProspect() {
      if (!this.$refs.form.validate()) {
        return;
      }

      try {
        const response = await this.$http.post("/prospects", {
          clientId: this.selectedClient,
          companyName: this.companyName,
          firstName: this.firstName,
          contactLastName: this.contactLastName,
          lastName: this.lastName,
          email: this.email,
          title: this.title,
          linkedInProfileUrl: this.linkedInProfileUrl,
          website: this.website,
          phone: this.phone,
        });
        this.$emit("prospectSaved", response.data);
      } catch (err) {
        this.$emit("failedCreatingProspect", err);
      }
    },
  },
};
</script>
